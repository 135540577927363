import { Link } from "gatsby"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Footer from "../../components/footer"
import Head from "../../components/header"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"
import * as Style from '../../components/toppage.module.css'

const extlink = (url, text) => (
  <>
    <a href={url} className="text-decoration-none me-1" target="_blank" rel="noopener noreferrer">{text}</a>
  </>
)

const MediaPage = () => (
  <Layout>
    <Head pageTitle="お知らせ - 株式会社GAZIRU" pageLang="ja" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>お知らせ</h4>
          <h5>2025/02/21</h5>
          <p>
          2025年3月7日に大阪産業創造館で開催される「表面処理加工技術展2025」の
          <a className="text-decoration-none mx-1" href="https://www.asahi-printing.ink" target="_blank" rel="noopener noreferrer">
          アサヒプリンティング株式会社
          </a> 様の展示に、当社のGAZIRU個体識別サービスが利用されます。</p>
          <p>
          <a className="text-decoration-none mx-1" href="https://www.asahi-printing.ink/post/%E8%A1%A8%E9%9D%A2%E5%87%A6%E7%90%86%E5%8A%A0%E5%B7%A5%E5%B1%95%EF%BC%92%EF%BC%90%EF%BC%92%EF%BC%95-%E5%87%BA%E5%B1%95%E3%81%97%E3%81%BE%E3%81%99%EF%BC%81%EF%BC%81" target="_blank" rel="noopener noreferrer">
          アサヒプリンティング株式会社 - お知らせ
          </a>
          </p>
          
          <h5>2024/11/11</h5>
          <p>東京ビックサイトで開催された
            <a className="text-decoration-none mx-1" href="https://www.jimtof.org/jp/index.html" target="_blank" rel="noopener noreferrer">
            JIMTOF2024</a>（日本国際工作機械見本市）にて、GAZIRU個体識別を用いた履歴管理の展示を行いました。<br />
            また、ワークショップ「ものづくりにおけるトレーサビリティの課題と対策」(画像認識による個体識別)にて、
            当社代表が講演いたしました。<br />
            多数ご来場いただき、ありがとうございました。</p>
          <hr />

          <h5>2024/07/29</h5>
          <p>2024年7月24日に公開された「アーティゾン美術館公式アプリ」の音声ガイド機能に、
            高精度での作品判別を実現する当社の画像認識エンジン「GAZIRU平面物認識」が搭載されました。<br />
            7月27日からアーティゾン美術館で開催されている「空間と作品」展からご利用になれます。</p>
            <div className={Style.ref}>
              <span>参考情報:</span><br />
              <div className={Style.ref}>
                <a className="text-decoration-none mx-4" href="https://www.artizon.museum" target="_blank" rel="noopener noreferrer">
                公益財団法人石橋財団 アーティゾン美術館</a><br />
                <a className="text-decoration-none mx-5" href="https://www.artizon.museum/wp-content/uploads/2024/07/ATZ240725_A_J.pdf" target="_blank" rel="noopener noreferrer">
                プレスリリース</a><br />
                <a className="text-decoration-none mx-4" href="https://artexhibition.jp/topics/news/20240726-AEJ2229865/" target="_blank" rel="noopener noreferrer">
                美術展ナビ</a>
              </div>
            </div><br />
          <hr />

          <h5>2023/07/20</h5>
          <p>TechShare社「DOBOT User Conference 2023」にて、GAZIRU個体識別の撮像位置の安定のために
            ロボットアーム DOBOT MG400 を使用した事例をご紹介しました。
            <a className="text-decoration-none mx-1" href="https://techshare.co.jp/innovation-week2023/" target="_blank" rel="noopener noreferrer">
            DOBOT User Conference 2023</a><br />
            たくさんのご参加ありがとうございました。</p>
          <hr />

          <h5>2023/03/03</h5>
          <p>ASCII STARTUP 主催 <a className="text-decoration-none mx-1" href="https://jid-ascii.com" target="_blank" rel="noopener noreferrer">
            JAPAN INNOVATION DAY</a>
            2023 に出展しました。<br />
            たくさんのご参加ありがとうございました。</p>
          <hr />

          <h5>2023/01/25</h5>
          <p>日本経済新聞（紙面版）に弊社に関する記事が掲載されました。</p>
          <hr />

          <h5>2022/11/12</h5>
          <p>東京ビックサイトで開催された
            <a className="text-decoration-none mx-1" href="https://www.jimtof.org/jp/index.html" target="_blank" rel="noopener noreferrer">
            JIMTOF2022
            </a>（日本国際工作機械見本市）のワークショップ
            「ものづくりにおけるトレーサビリティの課題と対策」(画像認識による個体識別)にて、
            当社代表が講演しました。<br />
            たくさんのご参加ありがとうございました。</p>
          <hr />

          <h5>2022/07/15</h5>
          <p>会社概要ページ > アクセス の情報を更新しました。:
            <Link className="text-decoration-none mx-1" to="/ja/about">会社概要</Link></p>
          <hr />

          <h5>2022/06/20</h5>
          <p>日本経済新聞（紙面版）に弊社に関する記事が掲載されました。</p>
          <hr />

          <h5>2021/10/19</h5>
          <p>株式会社CAICAテクノロジーズとNFT プラットフォームの拡販に関するアライアンスを締結しました。</p>
            <div className={Style.ref}>
              <span>参考情報:</span><br />
              <div className={Style.ref}>
                <a className="text-decoration-none mx-1" href="https://www.caica.jp/wp-content/uploads/2021/10/20211019_1_oshirase.pdf" target="_blank" rel="noopener noreferrer">
                NFT プラットフォームの拡販に関するアライアンスを締結　～画像認識サービス「GAZIRU」と連携～（株式会社CAICA，IRニュース）
                </a><br />
                <a className="text-decoration-none mx-1" href="https://www.caica-technologies.co.jp/wp-content/uploads/2021/10/20211019_2_CT.pdf" target="_blank" rel="noopener noreferrer">
                NFT プラットフォームの拡販に関するアライアンスを締結　～画像認識サービス「GAZIRU」と連携～（株式会社CAICAテクノロジーズ，お知らせ）
                </a><br />
              </div>
            </div><br />          
          <hr />

          <h5>2021/10/12</h5>
          <p>2021/10/5 に開催されたNECの製造業様向けWebセミナーにてGAZIRUの技術が紹介されました。<br/>
          たくさんのご参加ありがとうございました。</p>
          <hr />

          <h5>2021/08/03</h5>
          <p>{extlink("https://www.nikko-pb.co.jp/products/detail.php?product_id=5110",
            "日本工業出版 画像ラボ 2021年8月号")}
            に弊社の画像認識技術の解説記事が掲載されました。</p>
          <hr />

          <h5>2021/07/30</h5>
          <p><a className="text-decoration-none me-2" href="https://www.youtube.com/watch?v=hSXelFIz_Uo" target="_blank" rel="noopener noreferrer">
              GAZIRUのスマホアプリの新機能紹介動画
             </a>をYoutubeに公開しました。</p>
          <hr />

          <h5>2021/07/07</h5>
          <p>2021/07/06 に開催したNECの
            「製造業を強くするAI導入の秘訣」WEBセミナー
            にて、GAZIRUの技術が紹介されました。<br />
            たくさんのご参加ありがとうございました。</p>
          <hr />

          <h5>2021/05/26</h5>
          <p>Gigabit Ethernetカメラに対応した、GAZIRU個体識別Windowsクライアント V3.5をリリースしました。</p>
          <hr />

          <h5>2021/05/18</h5>
          <p>{extlink("https://japan.zdnet.com/article/35170886/",
            "ZDnet")}
            に弊社に関する記事が掲載されました。</p>
          <hr />

          <h5>2021/04/26</h5>
          <p>{extlink("https://www.nikkei.com/article/DGXZQOUC132VZ0T10C21A4000000/",
            "日経産業新聞(Web版)")}
            に弊社に関する記事が掲載されました。</p>
          <hr />

          <h5>2021/04/20</h5>
          <p>日経産業新聞（紙面版）に弊社に関する記事が掲載されました。</p>
          <hr />

          <h5>2021/03/08</h5>
          <p>2021/02/24、2021/03/02 開催のNECの製造業様向けWebセミナーにて、GAZIRUの技術が紹介されました。<br />
            たくさんのご参加ありがとうございました。</p>
          <hr />

          <h5>2021/02/08</h5>
          <p>2021/01/27 開催の
            <a className="text-decoration-none mx-1" href="https://intrapreneurs-hub10.peatix.com/" target="_blank" rel="noopener noreferrer">
              Intrapreneur's Hubのオンラインイベント</a>
            にて、GAZIRUが紹介されました。<br />
            たくさんのご視聴ありがとうございました。</p>
          <hr />

          <h5>2020/12/09</h5>
          <p>2020/12/02 に開催された
            「Google Cloud 製造業向け ソリューション ウェビナー」
            でGAZIRU個体識別サービスが紹介されました。<br />
            たくさんのご参加ありがとうございました。</p>
          <hr />

          <h5>2020/10/19</h5>
          <p>{extlink("https://ascii.jp/elem/000/004/029/4029516/",
            "ASCII.jp")}
            に、GAZIRUの個体識別サービスについての記事が掲載されました。</p>
          <hr />

          <h5>2020/09/23</h5>
          <p>東京エレクトロンデバイス社様の
            <a className="text-decoration-none mx-1" href="https://www.intel.co.jp/content/www/jp/ja/now/ai-park-online.html" target="_blank" rel="noopener noreferrer">
            オンラインセミナー
            </a>にて、GAZIRUの物体指紋技術が紹介されました。</p>
          <hr />

          <h5>2020/09/14</h5>
          <p>{extlink("https://news.mynavi.jp/article/20200908-1289094/",
            "マイナビニュース")}
            にGAZIRUのプレリリース情報が掲載されました。</p>
          <hr />

          <h5>2020/09/14</h5>
          <p>{extlink("https://techable.jp/archives/137179",
            "TECHABLE")}
            にGAZIRUのプレリリース情報が掲載されました。</p>
          <hr />

          <h5>2020/09/14</h5>
          <p>{extlink("https://www.excite.co.jp/news/article/Dreaminnovation_vent_news_cTOlDnWvsE/",
            "エキサイトニュース")}
            にGAZIRUのプレリリース情報が掲載されました。</p>
          <hr />

          <h5>2020/09/08</h5>
          <p>{extlink("https://www.e-logit.com/loginews/2016:102105.php",
            "財経新聞")}
            にGAZIRUのプレリリース情報が掲載されました。</p>
          <hr />

          <h5>2020/09/08</h5>
          <p>{extlink("https://prtimes.jp/main/html/rd/p/000000001.000062152.html",
            "PR Times")}
            にGAZIRUのプレリリース情報が掲載されました。</p>
          <hr />

          <h5>2020/09/08</h5>
          <p>プレスリリース:<br/>
          <a className="text-decoration-none mx-1" href="https://prtimes.jp/main/html/rd/p/000000001.000062152.html" target="_blank" rel="noopener noreferrer">
          画像認識技術を提供するGAZIRU、流通業向けに物体指紋認証技術を用いた個体識別サービスを提供開始</a></p>
          <hr />

          <h5>2020/08/30</h5>
          <p>2020/8/19, 2020/8/25 開催のNEC製造業向けWebセミナーにて、GAZIRUの物体指紋技術が紹介されました。</p>
          <hr />

          <h5>2020/07/21</h5>
          <p>{extlink("https://www.nikkei.com/article/DGKKZO61751380Q0A720C2TJ1000/",
            "日本経済新聞")}
            に弊社に関する記事が掲載されました。</p>
          <hr />

          <h5>2020/07/20</h5>
          <p>経済産業省 {extlink("https://co-hr-innovation.jp/",
            "出向起業等創出支援事業")}
            の一次公募に選出されました。</p>
          <hr />

          <h5>2020/07/16</h5>
          <p>GAZIRU製品紹介動画を公開しました。</p>
          <hr />

          <h5>2020/06/02</h5>
          <p>GAZIRU製品紹介動画を公開しました。</p>
          <hr />

          <h5>2020/04/27</h5>
          <p><Link className="text-decoration-none me-2" to="/ja/examples">
            導入事例ページ</Link>
            を公開しました。</p>
          <hr />


          <br />
          <p><Link className="text-decoration-none" to="/">Home</Link></p>

        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default MediaPage
